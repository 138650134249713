import axios from "axios";

import { prefixApiDomain } from "./utils";

export function processCheckout(
  userId,
  fiscalDetails,
  shippingDetails,
  products
) {
  const productsWithoutImages = products.map((product) => {
    return { ...product, data: { ...product.data, image: undefined } };
  });

  return axios.post(prefixApiDomain(`/api/checkout`), {
    userId,
    fiscalDetails,
    shippingDetails,
    products: productsWithoutImages,
  });
}
