import React from "react";
import { Link } from "gatsby";

const CheckoutSuccess = () => (
  <div>
    <p>Gracias por su pedido.</p>
    <p>
      Recibirá un correo electrónico con su aval de pedido y nos pondremos en
      contacto con usted para finalizar el proceso.
    </p>
    <Link to="/">Volver al Inicio</Link>
  </div>
);

export default CheckoutSuccess;
