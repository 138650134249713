import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import { CATEGORIES_ROUTE } from "@routes";

import { processCheckout } from "apiClient/checkout";

import BlankPage from "templates/BlankPage";

import withAuth from "contexts/withAuth";

import PrivateLayout from "layouts/PrivateLayout";
import UserLayout from "layouts/UserLayout";

import CheckoutContent from "pagesComponents/Checkout/CheckoutContent";
import CheckoutSuccess from "pagesComponents/Checkout/CheckoutSuccess";
import { withShoppingCart } from "components/ShoppingCart";
import getValuesFromUser from "utils/getValuesFromUser";
import getUserFromValues from "utils/getUserFromValues";

const getInitialValues = (filledValues) => ({
  storeName: "",
  storeAddress: "",
  storePostalCode: "",
  storeCity: "",
  storePhone: "",
  ...filledValues,
});

class CheckoutPage extends React.Component {
  state = {
    sent: false,
    errors: {},
  };

  validate = (values) => {
    let errors = {};

    // All fields required
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        errors[key] = "Requerido";
      }
    });

    return errors;
  };

  onChangeName = (name) => {
    this.setState((state) => {
      const currentErrors = state.errors;
      delete currentErrors[name];

      return { errors: { ...currentErrors } };
    });
  };

  handleSubmitCheckout = async (values) => {
    const shippingDetails = getUserFromValues(values).store;
    try {
      await processCheckout(
        this.props.user.id,
        this.props.user.fiscal,
        shippingDetails,
        this.props.cart
      );

      this.props.resetCart();
      this.setState({ sent: true });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { sent } = this.state;
    const { cart, user } = this.props;

    if (sent) {
      return (
        <PrivateLayout layout={UserLayout} title="Pedido enviado">
          <CheckoutSuccess />
        </PrivateLayout>
      );
    } else if (cart.length === 0) {
      return (
        <BlankPage title="Pedido" link="Ir de compras" to={CATEGORIES_ROUTE}>
          No tiene nada en su carrito aún.
        </BlankPage>
      );
    }

    const { storeName, storeAddress, storePostalCode, storeCity, storePhone } =
      getValuesFromUser(user);

    return (
      <PrivateLayout layout={UserLayout} title="Hacer pedido">
        <Formik
          enableReinitialize
          initialValues={getInitialValues({
            storeName,
            storeAddress,
            storePostalCode,
            storeCity,
            storePhone,
          })}
          validate={this.validate}
          onSubmit={this.handleSubmitCheckout}
        >
          {({ errors, handleChange, ...formikProps }) => (
            <CheckoutContent
              {...formikProps}
              errors={{ ...errors, ...this.state.errors }}
              handleChange={(event) => {
                this.onChangeName(event.target.name);
                handleChange(event);
              }}
            />
          )}
        </Formik>
      </PrivateLayout>
    );
  }
}

CheckoutPage.propTypes = {
  cart: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default withAuth(withShoppingCart(CheckoutPage));
