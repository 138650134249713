import React from "react";
import PropTypes from "prop-types";

import { CenterAligned, ButtonCss } from "./CheckoutContent.style";

import Input from "components/Input";
import Button from "components/Button";

const labels = {
  storeName: "Nombre",
  storeAddress: "Dirección",
  storePostalCode: "Código postal",
  storeCity: "Ciudad",
  storePhone: "Teléfono",
};

const CheckoutContent = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
}) => (
  <div>
    <p>Por favor, compruebe que la dirección de envío es correcta.</p>
    <form onSubmit={handleSubmit}>
      <Input
        label={labels.storeName}
        name="storeName"
        value={values.storeName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.storeName && touched.storeName && errors.storeName}
      />
      <Input
        label={labels.storeAddress}
        name="storeAddress"
        value={values.storeAddress}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.storeAddress && touched.storeAddress && errors.storeAddress
        }
      />
      <Input
        label={labels.storePostalCode}
        name="storePostalCode"
        value={values.storePostalCode}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.storePostalCode &&
          touched.storePostalCode &&
          errors.storePostalCode
        }
      />
      <Input
        label={labels.storeCity}
        name="storeCity"
        value={values.storeCity}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.storeCity && touched.storeCity && errors.storeCity}
      />
      <Input
        label={labels.storePhone}
        type="tel"
        name="storePhone"
        value={values.storePhone}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.storePhone && touched.storePhone && errors.storePhone}
      />
      <CenterAligned>
        <Button css={ButtonCss} type="submit">
          Confirmar pedido
        </Button>
      </CenterAligned>
    </form>
  </div>
);

CheckoutContent.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default CheckoutContent;
